import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/shared/layout'
import { ROUTES } from '../app.constant';
import '../assets/css/desktop.scss';
import logo from '../assets/images/desktop/logo.svg';
import banner from '../assets/images/desktop/banner.jpg';
import qrCode from '../assets/images/desktop/qr_code_prod.svg';
import pickUpDelivery from '../assets/images/desktop/pick_up_delivery.svg';
import repair24Hour from '../assets/images/desktop/repair_24_hour.svg';
import repairDamage from '../assets/images/desktop/repair_damage.svg';
import { initFingerPrintSDK } from '../app.utils';


const ScanPage = () => {

    useEffect(() => {
        document.title = '';
        const isRoute = JSON.parse(localStorage.getItem('isRouteFromPurchase'));
        if (!isRoute) {
            navigate(ROUTES.HOME);
        }else {
            initFingerPrintSDK(null, null, null);
            localStorage.setItem('isRouteFromPurchase', false);
        }
    }, [])

    return (
        <Layout header={false} isBackBtn={false}>
            <div className="qr-scan-page">
                <div className="main-header">
                    <div className="desktop-container">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className="home-banner">
                    <img src={banner} alt="" />
                </div>
                <div className="qrCodeSection">
                    <div className="desktop-container">
                        <div className="row">
                            <div className="col-7">Scan the QR code on the Galaxy device that you <br /> would like to protect to view this offer. </div>
                            <div className="col-5 text-right">
                                <img src={qrCode} width="102" height="102" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services">
                    <div className="desktop-container">
                        <h2 className="section-heading text-center">Why get Samsung Care+?</h2>
                        <div className="row justify-content">
                            <div className="col-4">
                                <div className="card">
                                    <div className="icon">
                                        <img src={repair24Hour} alt="" />
                                    </div>
                                    <div className="card-body-qr">
                                        <h2>Repair as fast as<br />24 hours</h2>
                                        <p>Don’t let a broken phone interrupt your daily life. We’ll have your phone repaired within a day. T&C applies.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card">
                                    <div className="icon">
                                        <img src={pickUpDelivery} alt="" />
                                    </div>
                                    <div className="card-body-qr">
                                        <h2>Free pick up &amp;<br />delivery</h2>
                                        <p>Stay where you are and stay safe. We’ll pick-up and return your device wherever you are for <b>FREE</b>.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="card">
                                    <div className="icon">
                                        <img src={repairDamage} alt="" />
                                    </div>
                                    <div className="card-body-qr">
                                        <h2>Repair for<br />any damage</h2>
                                        <p>Accidents happen but we're here for you. We'll fix your broken screen and other damage- even if it’s caused by liquid.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop-container desktop-footer">
                    <div>©Samsung All Rights Reserved </div>
                    <div>What is <a href="https://www.samsung.com/ph/offer/samsung-care-plus/">Samsung Care+?</a> | <a href="https://www.samsung.com/ph/info/privacy/">Privacy Policy</a></div>
                </div>
            </div>
        </Layout>
    )
}

export default ScanPage;